import { About } from '@pages/about/About'
import { Auth } from '@pages/auth/Auth'
import { Bookmarks } from '@pages/bookmarks/Bookmarks'
import { Following } from '@pages/friends/Following'
import { Users } from '@pages/users/Users'
import { News } from '@pages/news/News'
import { Profile } from '@pages/profile/Profile'
import { ProfileSettings } from '@pages/profilesettings/ProfileSettings'
import { Rewind } from '@pages/rewind/Rewind'
import { TermsOfService } from '@pages/termsofservice/TermsOfService'
import { PrivacyPolicy } from '@pages/privacypolicy/PrivacyPolicy'
import { Affiliate } from '@pages/affiliate/Affiliate'
import { Verified } from '@pages/verified/Verified'
import { Oaa } from '@pages/oaa/Oaa'
import { Livestream } from '@pages/livestream/Livestream'

export const routes = [
  {
    path: '/',
    exact: true,
    component: News,
    auth: true,
  },
  {
    path: '/profile/:id',
    exact: false,
    component: Profile,
    auth: true,
  },
  {
    path: '/livestream/:id',
    exact: false,
    component: Livestream,
    auth: true,
  },
  {
    path: '/profile/settings',
    exact: true,
    component: ProfileSettings,
    auth: true,
  },
  {
    path: '/following',
    exact: true,
    component: Following,
    auth: true,
  },
  {
    path: '/users',
    exact: true,
    component: Users,
    auth: true,
  },
  {
    path: '/bookmarks',
    exact: true,
    component: Bookmarks,
    auth: true,
  },
  {
    path: '/auth',
    exact: false,
    component: Auth,
    auth: false,
  },
  {
    path: '/about',
    exact: true,
    component: About,
    auth: true,
  },
  {
    path: '/terms-of-service',
    exact: true,
    component: TermsOfService,
    auth: true,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    auth: true,
  },
  {
    path: '/affiliate',
    exact: true,
    component: Affiliate,
    auth: true,
  },
  {
    path: '/verified',
    exact: true,
    component: Verified,
    auth: true,
  },
  {
    path: '/oaa',
    exact: true,
    component: Oaa,
    auth: true,
  },
  {
    path: '/rewind',
    exact: true,
    component: Rewind,
    auth: true,
  },
]
