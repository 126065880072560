import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Typography } from '@mui/material'

import { BackgroundPaperBox, BorderBox } from '@ui/ThemeBox'

export const Rewind: FC = () => {
  const { t } = useTranslation(['notFound'])
  document.title = t('Terms Of Service')

  return (
    <BorderBox sx={{ p: 3, mb: 2 }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        mt: 5,
      }}
    >
    
        <Typography variant="h4" align="center">
          <b>{t('Terms of Service')}</b>
        </Typography>
        <br />
        <Typography variant="subtitle1" align="center">
          {t(`Welcome to Talkie ("Service"). By using our Service, you agree to the following terms and conditions. Please read them carefully.`)}
        </Typography>
        <br />
        <Typography variant="body1" align='center'>
        <h3><b>1. Acceptance of Terms</b></h3>

By accessing or using our Service, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not use the Service.
<br/>
<h3><b>2. Description of Service</b></h3>

Talkie is a social networking platform that allows users to connect, share content, and interact with others. We may modify, suspend, or discontinue the Service at any time without notice.
<br/>
<h3><b>3. Eligibility</b></h3>

You must be at least 13 years old to use the Service. By using the Service, you represent and warrant that you meet the eligibility requirements.
<br/>
<h3><b>4. Account Registration</b></h3>

To use certain features of the Service, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated.

You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
<br/>
<h3><b>5. User Conduct</b></h3>

<b>You agree not to use the Service to:</b>
<br/>

- Violate any laws or regulations.<br/>
- Post or share any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable.<br/>
- Harass, threaten, or intimidate others.<br/>
- Impersonate any person or entity, or misrepresent your affiliation with a person or entity.<br/>
- Engage in any activity that could interfere with the functioning of the Service.
<br/>
<h3><b>6. Content Ownership and Licenses</b></h3>

You retain ownership of any content you post or share on the Service ("User Content"). However, by posting User Content, you grant Talkie a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, distribute, and display your User Content in connection with the Service.
<br/>
<h3><b>7. Privacy Policy</b></h3>

Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect your personal information.
<br/>
<h3><b>8. Termination</b></h3>

We may terminate or suspend your account and access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or the Service.
<br/>
<h3><b>9. Disclaimer of Warranties</b></h3>

The Service is provided "as is" and "as available" without any warranties of any kind, whether express or implied. We do not warrant that the Service will be uninterrupted, error-free, or free from viruses or other harmful components.
<br/>
<h3><b>10. Limitation of Liability</b></h3>

To the fullest extent permitted by law, Talkie shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Service.
<br/>
<h3><b>11. Indemnification</b></h3>

You agree to indemnify and hold harmless Talkie, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Service.
<br/>
<h3><b>12. Changes to the Terms</b></h3>

We may modify these Terms at any time. If we make material changes, we will provide notice through the Service or by other means. Your continued use of the Service after the changes become effective constitutes your acceptance of the new Terms.
<br/>
<h3><b>13. Governing Law</b></h3>

These Terms are governed by and construed in accordance with the laws of RedMoonko, without regard to its conflict of laws principles.
<br/>
<h3><b>14. Contact Information</b></h3>

If you have any questions about these Terms, please contact us at support@talkie.lol.
<br/>
---
<br/>
By using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        </Typography>
      

    </Box>
    </BorderBox>
  )
}
